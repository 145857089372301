/* src/Projects.css */
.projects-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: 1fr;
    gap: 20px;
}

.project {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.project img {
    width: 100%;
    height: 200px;
    /* Adjust height as needed */
    object-fit: cover;
    display: block;
}

.project-info {
    padding: 15px;
}

.project-info h2 {
    margin: 10px 0;
    font-size: 1.2em;
}

.project-info p {
    margin: 5px 0;
}

.project-info button {
    background: #ff4b4b;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.2s ease-in-out;
}

.project-info button:hover {
    background: #ff0000;
}

.add-project-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-project-form input,
.add-project-form textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.add-project-form button {
    padding: 10px;
    border-radius: 5px;
    background-color: #00796b;
    color: white;
    border: none;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.add-project-form button:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .projects-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 10px;
    }

    .project-info h2 {
        font-size: 1em;
    }

    .project-info button {
        width: 100px;
    }

    .add-project-form {
        gap: 5px;
    }

    .add-project-form input,
    .add-project-form textarea,
    .add-project-form button {
        padding: 8px;
    }

    .add-image-form button {
        width: 100px;
    }
}

@media (max-width: 480px) {
    .projects-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .add-project-form {
        gap: 5px;
    }

    .add-project-form input,
    .add-project-form textarea,
    .add-project-form button {
        padding: 8px;
    }
}