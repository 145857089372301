/* src/components/Footer.css */

.site-footer {
  background-color: #004d40; /* Dark green */
  color: white;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #ddd;
}

footer {
  background-color: #004d40;
  color: white;
  text-align: center;
  padding: 2rem 1rem;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

.footer-left p {
  margin: 0;
  text-align: center;
  flex-basis: 100%; /* Take full width on small screens */
}

.footer-right a {
  margin: 0 15px; /* Increased spacing */
  font-size: 1.5rem;
}

.footer-right .icon {
  color: white; /* Default icon color */
  transition: color 0.3s ease-in-out;
}

.footer-right .facebook-icon:hover {
  color: #3b5998; /* Facebook color */
}

.footer-right .twitter-icon:hover {
  color: #1DA1F2; /* Twitter color */
}

.footer-right .linkedin-icon:hover {
  color: #0077b5; /* LinkedIn color */
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .footer-right {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .footer-right a {
    margin: 10px; /* Adjusted for better spacing on mobile */
  }
}
