/* AboutUs.css - Existing styles are in place, we're adding and modifying styles for enhancement */

.hero-section {
  background-size: cover;
  background-position: center;
  height: 400px;
  /* Consider increasing height for more impact */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  /* White text for contrast */
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}

.hero-content {
  text-align: center;
  max-width: 900px;
  /* Control the width of the content */
  padding: 2rem;
}

.hero-overlay {
  background: rgba(0,
      0,
      0,
      0.7);
  /* Increase the opacity for better readability */
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 900px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  /* Adding shadow for depth */
}

.content-section {
  display: flex;
  flex-direction: column;
  /* Stack cards vertically */
  align-items: center;
  /* Center cards horizontally */
  padding: 2rem;
}

.card {

  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 1rem 0;
  width: 80%;
  max-width: 800px;
  text-align: justify;
  /* Justify text for better readability */
}

.card h2 {
  color: #00796b;
}

.contact-detail {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.icon {
  font-size: 1.5rem;
  /* Increase icon size for better visibility */
  color: #00796b;
  /* Adjusted for brand color */
  margin-right: 1rem;
}

a {
  color: #00796b;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #005f73;
}

.btn-link {
  background-color: #00796b;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  margin: 1rem 0;
  /* Add space around buttons */
  font-weight: bold;
  /* Make text bold to stand out */
}

.btn-link:hover {
  text-decoration: none;
}

.hero-title {
  font-size: 2.5rem;
  /* Larger font size for the title */
  margin-bottom: 0.5rem;
  /* Space between title and subtitle */
}

.hero-subtitle {
  font-size: 1.2rem;
  /* Subtitle size, not too large to overpower the title */
  margin-bottom: 1rem;
  /* Space below subtitle if you add a button */
}

/* Responsive design adjustments */
@media (max-width: 1024px) {
  .hero-section {
    height: 350px;
    /* Adjust hero section for medium screens */
  }

  .card {
    width: 90%;
    /* Allow cards to take more width on medium screens */
  }
}

@media (max-width: 768px) {
  .hero-overlay {
    padding: 1rem;
  }

  .card {
    width: 95%;
    /* Cards take almost full width on smaller screens */
    padding: 1.5rem;
    /* Slightly reduce padding for more space */
    text-align: center;
  }

  .btn-link {
    padding: 8px 16px;
    font-size: 0.9rem;
    width: auto;
    /* Let the button size be determined by its content */
    margin: 10px 0;
    /* More vertical margin for stacked buttons */
  }

  .vision-mission {
    padding: 1rem;
  }

  .hero-section {
    height: 250px;
    /* Smaller height on mobile */
  }

  .hero-title {
    font-size: 1.5rem;
    /* Smaller font size for mobile */
  }

  .hero-subtitle {
    font-size: 1rem;
    /* Adjust subtitle size for mobile */
  }

  p.hero-subtitle {
    font-size: 20px;
    /* Adjust subtitle size for mobile */
    color: #fff;
  }

  div.hero-content {
    background: rgba(0, 0, 0, 0.7);
    padding: 7px;
    margin: 7px;
    border-radius: 20px;
    text-align: center;
    max-width: 100vw;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

}

/* Ensure that hero image maintains aspect ratio and covers the space */
.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  /* Slightly see-through for text to stand out */
  z-index: -1;
  /* Ensure the image is behind the content */
}

/* Additional global styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
}

/* Global anchor tag styles for consistent link appearance */
a {
  color: inherit;
  /* Links use the text color they're in */
  text-decoration: none;
  /* No underline */
}

a:hover {
  text-decoration: underline;
  /* Underline on hover for indication */
}