.contact-us-page {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.contact-intro, .contact-details, .contact-form, .social-media {
  background-color: white;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 800px;
}

.contact-detail {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  width: 100%; /* Full width to use all available space */
  margin: 1rem 0; /* Space between each contact detail */
}
  


.contact-intro h2, .contact-details h2, .contact-form h2, .social-media h2 {
  color: #005f73;
  margin-bottom: 1rem;
}

.contact-method {

  gap: 10px;
  align-items: center; /* Ensure icon and h3 are vertically aligned */
  margin-bottom: 1rem;
}


.contact-method .text {
  display: block; /* Forces text to the next line */
  width: 100%; /* Takes full width to push it to a new line */
}

.icon {
  margin-right: 10px; /* Space between icon and text */
  color: #005f73; /* Uniform color for icons */
  font-size: 24px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input, .contact-form textarea {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.contact-form button {
  padding: 1rem 2rem;
  background-color: #004d40;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #003529;
}

.contact-info {
  display: flex;
  justify-content: flex-start; /* Changed from 'start' to 'flex-start' */
  flex-wrap: wrap;
  gap: 1rem;
}

.contact-method .icon {
  justify-self: center; /* Center the icon in its grid cell */
  color: #005f73; /* Example color */
  font-size: 24px; /* and any other styling you need */
}

.contact-method .details {
  grid-column: 2 / -1; /* Make details start from second column and span to the end */
  display: block; /* Separate block for details */
}

/* Ensure links are displayed on new lines */
.contact-method a {
  display: block; /* Each link on a new line */
  margin-top: 0.5rem; /* Spacing between links */
}

/* Additional CSS to address requirements */
.contact-method a, .social-media a {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color from parent */
}

.contact-method a:hover, .social-media a:hover {
  text-decoration: none; /* No underline on hover */
}

.social-media a {
  margin-right: 20px; /* Space between social media icons */
}

.fa-icon {
  color: #005f73; /* Set your desired color */
}

.contact-details {
  display: flex; /* Use flexbox to layout children */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Align children centrally */
  background-color: white;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 800px;
}

a {
  color: #005f73; /* Link color matching the icons */
  text-decoration: none; /* No underline */

}

.maps-directions, .feedback-section {
  background-color: white;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 800px;
}

.maps-directions ul {
  list-style-type: none; /* Removes bullet points */
  padding: 0;
}

.maps-directions li a {
  color: #005f73; /* Uniform color for links */
  text-decoration: none;
}

.maps-directions li a:hover {
  text-decoration: underline; /* Adds underline on hover */
}

@media (max-width: 768px) {
  .contact-intro, .contact-details, .contact-form, .social-media {
    padding: 1rem;
  }

  .contact-method {
    flex-direction: column;
    align-items: center;
  }
}
