.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  height: 100vh;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  position: relative;
  color: #fff;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 300px;
  margin: 200px;
  animation: appear 1.5s ease-out forwards;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-content h1 {
  font-size: calc(1rem + 1vw);
  margin-bottom: 0.5rem;
  margin-top: 50px
}

.hero-content p {
  font-size: calc(1rem + 0.5vw);
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 2rem;
}

.hero-btn {
  text-decoration: none;
  background-color: #00796b;
  color: #ffffff;
  padding: 12px 35px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.hero-btn:hover {
  background-color: #004d40;
  transform: scale(1.05);
  text-decoration: none;
  color: white;
}


@media (max-width: 768px) {
  .hero-section {
    height: auto;
    padding: 50px 20px;
  }

  .hero-content {
    background-color: transparent;
    padding: 0;
  }

  .hero-content h1 {
    font-size: 24px;
    animation: text-focus-in 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  .hero-content p {
    font-size: 18px;
    margin-bottom: 2rem;
    animation: slide-in-blurred-bottom 1s cubic-bezier(0.23, 1, 0.32, 1) both;
  }

  .hero-btn {
    animation: jelly 0.5s infinite;
  }
}

/* Keyframes for Creative Animations */
@keyframes text-focus-in {
  0% {
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-bottom {
  0% {
    transform: translateY(50px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 100%;
    filter: blur(40px);
    opacity: 0;
  }

  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes jelly {

  0%,
  100% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(0.95);
  }
}